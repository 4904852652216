.profile-page-bgn{
    background-image: url('/public/bg.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.logo{
  margin: auto;
}
.profile-page {
    max-width: 1000px;
    background-color: #ccc;
    margin: 0 auto;
    padding: 50px 30px;
    border-radius: 30px;
  }
  
  .profile-image {
    max-width: 200px;
    height: auto;
    border-radius: 50%;
  }
  
  .profile-content {
    margin-top: 20px;
  }
  
  @media (max-width: 640px) {
    .profile-image {
      max-width: 150px;
    }
  }
  
  ol, ul, menu{
    list-style: disc;
  }