.login-page {
    background-image: url('/public/bg.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container {
    max-width: 500px;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 10px;
  }
  
  .logo {
    width: 100px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .login-button {
    width: 100%;
    background-color: green;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .reset-password-link {
    color: green;
    text-decoration: none;
  }
  
  @media (min-width: 768px) {
    .container {
      display: flex;
      align-items: center;
    }
  
    .logo {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
  